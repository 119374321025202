<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LOAN APPLICATION</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                readonly
                v-model="branch"
                label="Branch"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                type="date"
                v-model="date_accomplished"
                label="Date Released"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="laf_no"
                label="LAF No."
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              md="12"
              cols="12"
            >
              <v-card
                flat
              >
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-col
                    md="12"
                    cols="12"
                  >
                    <v-select
                      class="mx-2"
                      v-model="search"
                      @change="get_search_items_info"
                      :items="search_items"
                      item-value="id"
                      item-text="name"
                      placeholder="Search by (Lastname/Firstname/CMF #)"
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  ></v-col>
                  <v-row>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="last_name"
                        label="Last Name"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="first_name"
                        label="First Name"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="middle_name"
                        label="Middle Name"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="date_of_birth"
                        label="Date of Birth"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        type="date"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="place_of_birth"
                        dense
                        label="Place of Birth"
                        outlined
                        :rules="rules.default_max_255_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-text-field
                        v-model="height"
                        type="number"
                        dense
                        label="Height"
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-text-field
                        v-model="weight"
                        type="number"
                        dense
                        label="Weight"
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>


                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="tin_no"
                        dense
                        outlined
                        label="Tax ID Number (TIN)"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="sss_no"
                        dense
                        outlined
                        label="SSS Number"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-text-field
                        v-model="type_of_id"
                        dense
                        outlined
                        label="Type of ID"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-text-field
                        v-model="id_no"
                        dense
                        outlined
                        label="ID #"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-row cols="12"
                           md="2" class="pt-6">
                      <v-avatar
                        rounded
                        size="40"
                        class="me-6"
                      >
                        <v-img :src="cropped"></v-img>
                      </v-avatar>
                      <v-btn
                        color="primary"
                        @click="$refs.refInputEl.click()"
                      >
                        <v-icon class="d-sm-none">
                          {{ icons.mdiCloudUploadOutline }}
                        </v-icon>
                        <span class="d-none d-sm-block">Upload</span>
                      </v-btn>
                      <input
                        ref="refInputEl"
                        type="file"
                        accept=".jpeg,.jpg,.png"
                        :hidden="true"
                        v-on:click="$refs.refInputEl.value = ''"
                        v-on:change="croppie"
                      />
                    </v-row>

                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-select
                        v-model="civil_status"
                        dense
                        outlined
                        label="Civil Status"
                        :items="['Single','Married','Widowed','Separated']"
                        :rules="rules.combobox_rule"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="no_of_dependents"
                        dense
                        outlined
                        label="No. Of Dependents"
                        type="number"
                        min="0"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-select
                        v-model="gender"
                        dense
                        outlined
                        label="Gender"
                        :items="['Male','Female']"
                        :rules="rules.combobox_rule"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="present_address"
                        dense
                        outlined
                        label="Present Address"
                        :rules="rules.default_max_255_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-select
                        v-model="residence_is"
                        dense
                        outlined
                        label="Residence is"
                        :items="['Owned','Rented','Mortgaged','Others']"
                        :rules="rules.combobox_rule"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-if="residence_is=='Others'"
                        v-model="residence_specify"
                        dense
                        outlined
                        label="Specify (if others)"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-text-field
                        v-model="permanent_address"
                        dense
                        outlined
                        label="Permanent Address"
                        :rules="rules.default_max_255_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="religion"
                        dense
                        outlined
                        label="Religion"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="citizenship"
                        dense
                        outlined
                        label="Citizenship"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="contact_no"
                        dense
                        outlined
                        label="Contact Number/s"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="email_address"
                        dense
                        outlined
                        label="Email Address"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              md="12"
              cols="12"
            >
              <v-card
                flat
              >
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h4 class="font-weight-light">EMPLOYMENT/BUSINESS
                    INFORMATION</h4>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-col
                    md="4"
                    cols="12"
                  ></v-col>
                  <v-row>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="occupation"
                        label="Occupation"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="employment_date"
                        label="Length of Service/Date Started"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="employer"
                        label="Employer/Business Name"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="8"
                    >
                      <v-text-field
                        v-model="employer_address"
                        label="Employer/Business Address"
                        dense
                        outlined
                        :rules="rules.default_max_255_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="employer_contact_no"
                        dense
                        label="Contact Number/s"
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="8"
                    >
                      <v-select
                        v-model="monthly_income"
                        dense
                        outlined
                        label="Which of the following comes closest to your monthly income in pesos"
                        :items="['Under 8,000','8,000-15,000','15,001-30,000','30,001-50,000','50,001-100,000','Above 100,000']"
                        :rules="rules.combobox_rule"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-select
                        v-model="employment_status"
                        dense
                        outlined
                        label="Employment Status"
                        :items="['Regular/Permanent','Contractual','Self Employed','Others']"
                        :rules="rules.combobox_rule"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-col
                    md="4"
                    cols="12"
                  ></v-col>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              md="12"
              cols="12"
            >
              <v-card
                flat
              >
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h4 class="font-weight-light">SPOUSE'S INFORMATION/FAMILY
                    REPRESENTATIVE</h4>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-col
                    md="4"
                    cols="12"
                  ></v-col>
                  <v-row>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="family_last_name"
                        label="Last Name"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="family_first_name"
                        label="First Name"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="family_middle_name"
                        label="Middle Name"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="family_date_of_birth"
                        label="Date of Birth"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        type="date"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="family_contact_no"
                        dense
                        label="Contact Number/s"
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="family_occupation"
                        dense
                        label="Occupation"
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="family_employer"
                        dense
                        outlined
                        label="Employer/Business"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="8"
                    >
                      <v-text-field
                        v-model="family_employer_address"
                        dense
                        outlined
                        label="Business/Employer's Address/Contact Number"
                        :rules="rules.default_max_255_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="family_monthly_income"
                        dense
                        outlined
                        label="Monthly Income (in peso)"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="family_employment_status"
                        dense
                        outlined
                        label="Employment Status"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="family_employment_date"
                        dense
                        outlined
                        label="Length of Service/Date Started"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              md="12"
              cols="12"
            >
              <LoansCalculator
                :key="this.keyLoansCalculator"
                :is_approved="true"
                :value="this.value"
                :member_to_loan="this.member_to_loan"
                :can_edit="false"
                v-on:data="calculation_of_loan"
                v-on:business_permit="croppie_bus"
              ></LoansCalculator>
            </v-col>
            <v-col
              md="12"
              cols="12"
            >
              <v-card
                flat
              >
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h4 class="font-weight-light">CO-MAKER INFOMATION</h4>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-col
                    md="12"
                    cols="12"
                  >
                    <v-select
                      class="mx-2"
                      v-model="search_co"
                      @change="get_search_items_info_co"
                      :items="search_items_co"
                      item-value="id"
                      item-text="name"
                      placeholder="Search by (Lastname/Firstname/CMF #)"
                      dense
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  ></v-col>
                  <v-row>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="last_name_co"
                        label="Co-Maker Last Name"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="first_name_co"
                        label="Co-Maker First Name"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="middle_name_co"
                        label="Co-Maker Middle Name"
                        dense
                        outlined
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        readonly
                      ></v-text-field>
                    </v-col>


                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="permanent_address_co"
                        dense
                        outlined
                        label="Permanent Address"
                        :rules="rules.default_max_255_character_and_no_empty_rule"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        :items="collectors_items"
                        item-value="id"
                        item-text="name"
                        label="Assigned Collector By"
                        required
                        :rules="rules.combobox_rule"
                        v-model="collector_id"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              md="12"
              cols="12"
            >
              <v-card
                flat
              >
                <v-card-text class="d-flex">
                  <v-avatar
                    rounded
                    size="250"
                    class="me-6"
                  >
                    <v-img :src="cropped_home"></v-img>
                  </v-avatar>

                  <!-- upload photo -->
                  <div>
                    <v-btn
                      color="primary"
                      class="me-3 mt-5"
                      @click="$refs.refInputEl_home.click()"
                    >
                      <v-icon class="d-sm-none">
                        {{ icons.mdiCloudUploadOutline }}
                      </v-icon>
                      <span class="d-none d-sm-block">Upload Scan Home Sketch / Place</span>
                    </v-btn>

                    <input
                      ref="refInputEl_home"
                      type="file"
                      accept=".jpeg,.jpg,.png"
                      :hidden="true"
                      v-on:click="$refs.refInputEl_home.value = ''"
                      v-on:change="croppie_home"
                    />

                    <v-btn
                      color="error"
                      outlined
                      class="mt-5"
                      @click="resett()"
                    >
                      Reset
                    </v-btn>
                    <p class="text-sm mt-5">
                      Allowed JPG or PNG
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-checkbox
                v-model="is_with_signature"
                label="With Signature"
                class="mt-0"
                hide-details
              ></v-checkbox>
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_loan_application"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import 'croppie/croppie.css'// import the croppie css manually
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import LoansCalculator from '@/views/create_interface/LoansData'

  export default {
    components: {
      snackBarDialog,
      LoansCalculator,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    created() {
      this.loan_counter_data()
    },
    data() {
      return {
        keyLoansCalculator: 0,
        loan_insurance: '0',
        loan_insurance_comm: '0',
        loan_insurance_addtnl: '0',
        loan_processing_fee: '0',
        mortuary_insurance: '250',
        foundation: '50',

        is_deminishing: false,
        saving: false,
        alert: false,
        alert_message: '',
        croppieImage: '',
        cropped: this.avatarImg,
        croppieImage_bus: '',
        cropped_bus: this.avatarImg,
        croppieImage_home: '',
        cropped_home: this.avatarImg,

        search: '',
        search_member: '',
        search_items: [],

        search_co: '',
        search_member_co: '',
        search_items_co: [],
        interest_per_month: [],
        premium_per_month: [],

        collectors_items: [],
        collector_id: 0,

        date_accomplished: '',
        laf_no: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        date_of_birth: '',
        place_of_birth: '',
        height: '',
        weight: '',
        tin_no: '',
        sss_no: '',
        type_of_id: '',
        id_no: '',
        civil_status: '',
        no_of_dependents: '',
        gender: '',
        present_address: '',
        residence_is: '',
        residence_specify: '',
        permanent_address: '',
        religion: '',
        citizenship: '',
        contact_no: '',
        email_address: '',
        occupation: '',
        employment_date: '',
        employer: '',
        employer_address: '',
        employer_contact_no: '',
        monthly_income: '',
        employment_status: '',
        family_last_name: '',
        family_first_name: '',
        family_middle_name: '',
        family_date_of_birth: '',
        family_contact_no: '',
        family_occupation: '',
        family_employer: '',
        family_employer_address: '',
        family_monthly_income: '',
        family_employment_status: '',
        family_employment_date: '',
        type_of_collateral: '',
        specify_collateral: '',
        is_with_signature: true,

        type_of_loan_others: '',
        due_amount: '',
        interest: '',
        interestt: 0,
        due_interest: 0,
        loan_savings: '',
        loan_savingss: '',
        total: '',
        totall: '',
        ci_items: [],
        ci_id: 0,
        amount_due: 0,
        due_loan_savings: '',

        last_name_co: '',
        first_name_co: '',
        middle_name_co: '',
        permanent_address_co: '',
        total_net: '0',
        nmapi: '0',
        misc: '0',
        member_to_loan: {},
      }
    },
    async mounted() {
      // this.search_member = this.member_id
      // this.search_member_co = this.co_maker_id
      // this.calculation_of_loan()
      await this.searching_search_items(this.member_id)
      await this.searching_search_items_co(this.co_maker_id)
      this.keyLoansCalculator++
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member === '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
      search_member_co: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member_co === '') {
            this.search_items_co = []
          } else {
            this.searching_search_items_co(this.search_member_co)
          }
        }, 500)
      },
    },
    props: {
      loan_app_id: Number,
      member_id: Number,
      co_maker_id: Number,
      mode_of_loan: String,
      type_of_loan: String,
      specify_type_of_loan: String,
      purpose_of_loan: String,
      terms: String,
      loan_amount: String,
      share_capital: String,
      regular_savings: String,
      mbai: String,
      value: Object,
      transmital: Object,
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['month_start', 'month_end', 'month_of', 'branch', 'branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_information', ['search_members_paid_member_id', 'search_members', 'search_members_paid']),
      ...mapActions('loans_data', ['loan_initialize_data', 'is_member_have_unpaid_loans', 'register_approve_new_loan']),
      reset() {

        this.loan_insurance = '0'
        this.loan_processing_fee = '0'
        this.mortuary_insurance = '250'
        this.foundation = '50'
        this.total_net = '0'

        this.is_deminishing = false
        this.saving = false
        this.alert = false
        this.alert_message = ''
        this.croppieImage = ''
        this.cropped = this.avatarImg

        this.croppieImage_bus = ''
        this.cropped_bus = this.avatarImg

        this.croppieImage_home = ''
        this.cropped_home = this.avatarImg

        this.search = ''
        this.search_member = ''
        this.search_items = []

        this.search_co = ''
        this.search_member_co = ''
        this.search_items_co = []

        this.laf_no = ''
        this.last_name = ''
        this.first_name = ''
        this.middle_name = ''
        this.date_of_birth = ''
        this.place_of_birth = ''
        this.height = ''
        this.weight = ''
        this.tin_no = ''
        this.sss_no = ''
        this.type_of_id = ''
        this.id_no = ''
        this.civil_status = ''
        this.no_of_dependents = ''
        this.gender = ''
        this.present_address = ''
        this.residence_is = ''
        this.residence_specify = ''
        this.permanent_address = ''
        this.religion = ''
        this.citizenship = ''
        this.contact_no = ''
        this.email_address = ''
        this.occupation = ''
        this.employment_date = ''
        this.employer = ''
        this.employer_address = ''
        this.employer_contact_no = ''
        this.monthly_income = ''
        this.employment_status = ''
        this.family_last_name = ''
        this.family_first_name = ''
        this.family_middle_name = ''
        this.family_date_of_birth = ''
        this.family_contact_no = ''
        this.family_occupation = ''
        this.family_employer = ''
        this.family_employer_address = ''
        this.family_monthly_income = ''
        this.family_employment_status = ''
        this.family_employment_date = ''
        this.due_date = ''
        this.is_with_signature = true

        this.type_of_loan_others = ''
        this.ci_items = []
        this.ci_id = 0

        this.loan_counter_data()
      },
      resett2() {
        this.last_name_co = ''
        this.first_name_co = ''
        this.middle_name_co = ''
        this.permanent_address_co = ''

        this.search_co = ''
        this.search_member_co = ''
        this.search_items_co = []
      },
      croppie(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped = this.croppieImage = e.target.result
        };

        reader.readAsDataURL(files[0]);
      },
      croppie_bus(data) {
        this.croppieImage_bus = data.croppieImage_bus
      },
      croppie_home(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped_home = this.croppieImage_home = e.target.result
        };

        reader.readAsDataURL(files[0]);
      },
      resett() {
        this.$refs.refInputEl.value = ''
        this.cropped = this.avatarImg

        this.$refs.refInputEl_bus.value = ''
        this.cropped_bus = this.avatarImg

        this.$refs.refInputEl_home.value = ''
        this.cropped_home = this.avatarImg
      },
      loan_counter_data() {
        this.date_accomplished = this.transmital.cheque_date
        this.loan_initialize_data({
          branch_id: this.branch_id,
        })
          .then(response => {
            this.laf_no = 'L-' + moment().year() + '-' + (response.data[0].counter + 1)
            this.ci_items = response.data[0].ci_data
            this.collectors_items = response.data[0].collectors_data
          })
          .catch(error => {
            this.saving = false
          })
        this.keyLoansCalculator++
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      calculation_of_loan(data) {
        this.collector_id = data.collector_id
        this.type_of_loan = data.type_of_loan
        this.type_of_loan_others = data.type_of_loan_others
        this.cropped_bus = data.cropped_bus
        this.purpose_of_loan = data.purpose_of_loan
        this.loan_amount = data.loan_amount
        this.mode_of_loan = data.mode_of_loan
        this.terms = data.terms
        this.due_amount = data.due_amount
        this.interest = data.interest
        this.interest_per_month = data.interest_per_month
        this.premium_per_month = data.premium_per_month
        this.is_deminishing = data.is_deminishing
        this.loan_savings = data.loan_savings
        this.total = data.total
        this.ci_id = data.ci_id
        this.loan_insurance = data.loan_insurance
        this.loan_processing_fee = data.loan_processing_fee
        this.mortuary_insurance = data.mortuary_insurance
        this.foundation = data.foundation
        this.nmapi = data.nmapi
        this.share_capital = data.share_capital
        this.regular_savings = data.regular_savings
        this.mbai = data.mbai
        this.total_net = data.total_net
        this.laf_no = data.laf_no
        this.due_date = data.due_date
        this.totall = data.totall
        this.interestt = data.interestt
        this.loan_savingss = data.loan_savingss
        this.loan_insurance_comm = data.loan_insurance_comm
        this.due_interest = data.due_interest
        this.due_loan_savings = data.due_loan_savings
        this.amount_due = data.amount_due
        this.type_of_collateral = data.type_of_collateral
        this.specify_collateral = data.specify_collateral
        this.misc = data.misc
      },

      searching_co(value) {
        this.search_member_co = value
      },
      searching_search_items(value) {
        this.member_to_loan = {}
        this.search_members_paid_member_id({
          branch_id: this.branch_id,
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      searching_search_items_co(value) {
        this.search_members_paid_member_id({
          branch_id: this.branch_id,
          search_word: value
        })
          .then(response => {
            this.search_items_co = response.data
          })
      },
      get_search_items_info() {
        this.member_to_loan = {}
        this.keyLoansCalculator++
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(this.search)
          if (index != -1) {
            this.last_name = this.search_items[index].member.last_name
            this.first_name = this.search_items[index].member.first_name
            this.middle_name = this.search_items[index].member.middle_name
            this.place_of_birth = this.search_items[index].member.place_of_birth
            this.tin_no = this.search_items[index].member.tin_no
            this.sss_no = this.search_items[index].member.sss_no
            this.civil_status = this.search_items[index].member.civil_status
            this.no_of_dependents = this.search_items[index].member.no_of_dependents
            this.gender = this.search_items[index].member.gender
            this.present_address = this.search_items[index].member.present_address
            this.residence_specify = this.search_items[index].member.residence_specify
            this.permanent_address = this.search_items[index].member.permanent_address
            this.religion = this.search_items[index].member.religion
            this.citizenship = this.search_items[index].member.citizenship
            this.contact_no = this.search_items[index].member.contact_no
            this.email_address = this.search_items[index].member.email_address
            this.occupation = this.search_items[index].member.occupation
            this.employment_date = this.search_items[index].member.employment_date
            this.employer = this.search_items[index].member.employer
            this.employer_address = this.search_items[index].member.employer_address
            this.employer_contact_no = this.search_items[index].member.employer_contact_no
            this.family_last_name = this.search_items[index].member.family_last_name
            this.family_first_name = this.search_items[index].member.family_first_name
            this.family_middle_name = this.search_items[index].member.family_middle_name
            this.family_contact_no = this.search_items[index].member.family_contact_no
            this.family_occupation = this.search_items[index].member.family_occupation
            this.family_employer = this.search_items[index].member.family_employer
            this.family_employer_address = this.search_items[index].member.family_employer_address
            this.family_monthly_income = this.search_items[index].member.family_monthly_income
            this.family_employment_status = this.search_items[index].member.family_employment_status
            this.family_employment_date = this.search_items[index].member.family_employment_date
            this.$refs.form.validate()
            this.member_to_loan = this.search_items[index].member
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
      get_search_items_info_co() {
        this.keyLoansCalculator++
        if (this.search_items_co.length > 0) {
          var index = this.search_items_co.map(function (x) {
            return x.id;
          }).indexOf(this.search_co)
          if (index != -1) {
            this.last_name_co = this.search_items_co[index].member.last_name
            this.first_name_co = this.search_items_co[index].member.first_name
            this.middle_name_co = this.search_items_co[index].member.middle_name
            this.permanent_address_co = this.search_items_co[index].member.permanent_address
          } else {
            this.resett2()
          }
        } else {
          l
          this.resett2()
        }
      },
      save_loan_application() {
        this.saving = true
        this.alert = false
        var flag = false
        if (moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_start, 'MMMM D, YYYY').format('MMMM D, YYYY')
          || moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_end, 'MMMM D, YYYY').format('MMMM D, YYYY')) {
          flag = true
        } else {
          var compareDate = moment(this.date_accomplished, 'YYYY-MM-DD')
          var startDate = moment(this.month_start, 'MMMM D, YYYY')
          var endDate = moment(this.month_end, 'MMMM D, YYYY')

          flag = compareDate.isBetween(startDate, endDate) //false in this case
        }
        if (flag === false) {
          this.date_accomplished = ''
          this.alert = true
          this.alert_message = 'Date Accomplished is Not Belong in Transaction Month!'
          this.saving = false
        } else {
          var proceed = true;
          if (this.croppieImage === '') {
            this.alert = true
            this.alert_message = 'Please Upload Valid ID'
            this.saving = false
            proceed = false
          }
          if (this.croppieImage_bus === '' && this.type_of_loan === 'Business') {
            this.alert = true
            this.alert_message = 'Please Upload Business Permit'
            this.saving = false
            proceed = false
          }
          if (this.croppieImage_home === '') {
            this.alert = true
            this.alert_message = 'Please Upload Home Sketch / Place'
            this.saving = false
            proceed = false
          }
          if (proceed) {
            if (this.$refs.form.validate()) {
              const data = new FormData()
              data.append('member_id', this.member_id);
              data.append('type_of_loan', this.type_of_loan);
              this.is_member_have_unpaid_loans(data)
                .then(response => {
                  if (response.data === 1) {
                    this.alert = true
                    this.alert_message = 'Member Already Have Unpaid Loan!'
                    this.saving = false
                  } else {
                    data.append('loan_insurance_comm', this.loan_insurance_comm);
                    data.append('loan_insurance_addtnl', this.loan_insurance_addtnl);
                    data.append('loan_insurance', this.loan_insurance);
                    data.append('loan_processing_fee', this.loan_processing_fee);
                    data.append('mortuary_insurance', this.mortuary_insurance);
                    data.append('regular_savings', this.regular_savings);
                    data.append('mbai', this.mbai);
                    data.append('deposit', this.regular_savings);
                    data.append('share_capital', this.share_capital);
                    data.append('foundation', this.foundation);
                    data.append('nmapi', this.nmapi);
                    data.append('misc', this.misc);
                    data.append('type_of_collateral', this.type_of_collateral);
                    data.append('specify_collateral', this.specify_collateral);
                    data.append('is_from_loan', true);
                    data.append('depositors_name', this.last_name + ', ' + this.first_name + ' ' + this.middle_name);

                    data.append('last_name', this.last_name);
                    data.append('first_name', this.first_name);
                    data.append('middle_name', this.middle_name);
                    data.append('branch_id', this.branch_id);
                    data.append('laf_no', this.laf_no);
                    data.append('date_accomplished', moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY'));
                    data.append('due_date', this.due_date);
                    data.append('specify_type_of_loan', this.specify_type_of_loan);
                    data.append('purpose_of_loan', this.purpose_of_loan);
                    data.append('mode_of_loan', this.mode_of_loan);
                    var splited_terms_loans = this.terms.split(' ')
                    data.append('terms', splited_terms_loans[0]);
                    data.append('amount', this.loan_amount);
                    data.append('interest', this.interestt);
                    data.append('interest_per_month', JSON.stringify(this.interest_per_month));
                    data.append('premium_per_month', JSON.stringify(this.premium_per_month));
                    data.append('is_deminishing', this.is_deminishing?1:0);
                    data.append('due_interest', this.due_interest);
                    data.append('loan_savings', this.loan_savingss);
                    data.append('due_loan_savings', this.due_loan_savings);
                    data.append('total', this.totall);
                    data.append('amount_due', this.amount_due);
                    data.append('principal', (parseFloat(this.amount_due) - (parseFloat(this.due_interest) + (parseFloat(this.due_loan_savings)))));
                    data.append('savings', this.due_loan_savings);
                    data.append('month_of', this.month_of);
                    data.append('collector_id', this.collector_id);

                    data.append('valid_id_picture', this.croppieImage);
                    data.append('business_permit', this.croppieImage_bus);
                    data.append('home_skecth', this.croppieImage_home);

                    data.append('date_accomplished', moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY'));
                    data.append('date_of_birth', moment(this.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'));
                    data.append('place_of_birth', this.place_of_birth);
                    data.append('height', this.height);
                    data.append('weight', this.weight);
                    data.append('tin_no', this.tin_no);
                    data.append('sss_no', this.sss_no);
                    data.append('specify_valid_id', this.type_of_id);
                    data.append('valid_id_no', this.id_no);
                    data.append('civil_status', this.civil_status);
                    data.append('no_of_dependents', this.no_of_dependents);
                    data.append('gender', this.gender);
                    data.append('present_address', this.present_address);
                    data.append('religion', this.religion);
                    data.append('residence', this.residence_is);
                    data.append('residence_others', this.residence_specify);
                    data.append('permanent_address', this.permanent_address);
                    data.append('citizenship', this.citizenship);
                    data.append('contact_no', this.contact_no);
                    data.append('email_address', this.email_address);
                    data.append('occupation', this.occupation);
                    data.append('type_of_loan', this.type_of_loan);
                    data.append('employment_date', this.employment_date);
                    data.append('employer', this.employer);
                    data.append('employer_address', this.employer_address);
                    data.append('employer_contact_no', this.employer_contact_no);
                    data.append('monthly_income', this.monthly_income);
                    data.append('employment_status', this.employment_status);
                    data.append('family_last_name', this.family_last_name);
                    data.append('family_first_name', this.family_first_name);
                    data.append('family_middle_name', this.family_middle_name);
                    data.append('family_date_of_birth', moment(this.family_date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'));
                    data.append('family_contact_no', this.family_contact_no);
                    data.append('family_occupation', this.family_occupation);
                    data.append('family_employer', this.family_employer);
                    data.append('family_employer_address', this.family_employer_address);
                    data.append('family_monthly_income', this.family_monthly_income);
                    data.append('family_employment_status', this.family_employment_status);
                    data.append('family_employment_date', this.family_employment_date);
                    data.append('teller_id', 1);
                    data.append('loan_app_id', this.loan_app_id);
                    data.append('co_maker_id', this.search_co);
                    data.append('ci_by_id', this.ci_id);
                    var with_signature = 0
                    if (this.is_with_signature) {
                      with_signature = 1
                    }
                    data.append('is_with_signature', with_signature);
                    this.register_approve_new_loan(data)
                      .then(response => {
                        this.change_snackbar({
                          show: true,
                          color: 'success',
                          text: response.data,
                        })
                        this.$emit('data')
                      })
                      .catch(error => {
                        this.alert = true
                        this.alert_message = error
                        console.log(error)
                        this.saving = false
                      })
                  }
                })
                .catch(error => {
                  this.alert = true
                  this.alert_message = error
                  this.saving = false
                })
            } else {
              this.alert = true
              this.alert_message = 'Please Fill up the field/s'
              this.saving = false
            }
          }
        }
      }
    }
  }
</script>
